<!--
 * @Description: 服务单质检
 * @Author: ChenXueLin
 * @Date: 2021-09-16 11:15:00
 * @LastEditTime: 2022-07-15 16:10:03
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="inspectionId">
              <el-input
                v-model="searchForm.inspectionId"
                placeholder="质检ID"
                title="质检ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="customerName">
              <el-input
                v-model="searchForm.customerName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneCallName">
              <el-input
                v-model="searchForm.phoneCallName"
                placeholder="来去电人名称"
                title="来去电人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneNumber">
              <el-input
                v-model="searchForm.phoneNumber"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scenarioName">
              <el-input
                v-model="searchForm.scenarioName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serverName">
              <el-input
                v-model="searchForm.serverName"
                placeholder="服务人"
                title="服务人"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="qualityPeopleName">
              <el-input
                v-model="searchForm.qualityPeopleName"
                placeholder="质检人"
                title="质检人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="qualityStatusCode">
              <e6-vr-select
                v-model="searchForm.qualityStatusCode"
                :data="qtStatusList"
                placeholder="质检状态"
                title="质检状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="reviewPeopleName">
              <el-input
                v-model="searchForm.reviewPeopleName"
                placeholder="审核人"
                title="审核人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="reviewStatusCode">
              <e6-vr-select
                v-model="searchForm.reviewStatusCode"
                :data="auditStatusList"
                placeholder="审核状态"
                title="审核状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="frameworkId">
              <!-- 部门下拉框 -->
              <e6-select
                v-model="searchForm.frameworkId"
                :dataList="selectData"
                placeholder="服务人组织机构"
                title="服务人组织机构"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="false"
                ref="t_select"
                class="treeSelect"
              ></e6-select>
            </el-form-item>

            <el-form-item class="search-item--2" prop="serverTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="服务时间"
                ref="effectiveDatePicker"
                v-model="searchForm.serverTime"
                title="服务时间"
                :picker-options="pickerOptions('searchForm.serverTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="服务时间（始）"
                end-placeholder="服务时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="qualityTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="质检时间"
                ref="effectiveDatePicker"
                v-model="searchForm.qualityTime"
                title="质检时间"
                :picker-options="pickerOptions('searchForm.qualityTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="质检时间（始）"
                end-placeholder="质检时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="reviewTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="审核时间"
                ref="effectiveDatePicker"
                v-model="searchForm.reviewTime"
                title="审核时间"
                :picker-options="pickerOptions('searchForm.reviewTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="审核时间（始）"
                end-placeholder="审核时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getCustomerServiceQTList,
  exportInspection,
  findDownList,
  getFrameworkTree
} from "@/api";
import { printError } from "@/utils/util";
import { exportExcelByUrl, exportMessageHandle } from "@/utils/download";
export default {
  name: "customerServiceQuality",
  components: {},
  data() {
    return {
      total: 0,
      selectData: [],
      searchForm: {
        inspectionId: "", //质检ID
        customerName: "", //客户名称
        phoneCallName: "", //来去电人名称
        phoneNumber: "", //来去电号码
        scenarioName: "", //场景名称
        serverName: "", //服务人名称
        frameworkId: [], //组织机构
        serverTime: [],
        serverTimeBeginTimestamp: "", //服务时间开始
        serverTimeEndTimestamp: "", //服务时间结束
        qualityPeopleName: "", //质检人名称
        qualityTime: [],
        qualityTimeBeginTimestamp: "", //质检时间开始时间
        qualityTimeEndTimestamp: "", //质检结束时间
        qualityStatusCode: "", //质检状态
        reviewTime: [], //审核时间
        reviewTimeBeginTimestamp: "", //审核时间
        reviewTimeEndTimestamp: "", //审核结束时间
        reviewStatusCode: "", //审核状态
        reviewPeopleName: "", //审核人
        pageIndex: 1,
        pageSize: 20
      },
      qtStatusList: [], //质检状态下拉框
      auditStatusList: [], //审核状态下拉框
      columnData: [
        {
          fieldName: "inspectionId",
          display: true,
          fieldLabel: "质检ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneCallName",
          display: true,
          fieldLabel: "来去电人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNumber",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scenarioName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverName",
          display: true,
          fieldLabel: "服务人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverTimeString",
          display: true,
          fieldLabel: "服务时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstServer",
          display: true,
          fieldLabel: "一级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondServer",
          display: true,
          fieldLabel: "二级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdServer",
          display: true,
          fieldLabel: "三级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callDuration",
          display: true,
          fieldLabel: "通话时长",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qualityStatus",
          display: true,
          fieldLabel: "质检状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "inspectionProject",
          display: true,
          fieldLabel: "质检项",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qualityAdvice",
          display: true,
          fieldLabel: "质检建议",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qualityPeopleName",
          display: true,
          fieldLabel: "质检人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qualityTimeString",
          display: true,
          fieldLabel: "质检时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reviewStatus",
          display: true,
          fieldLabel: "审核状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reviewAdvice",
          display: true,
          fieldLabel: "审核建议",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reviewPeopleName",
          display: true,
          fieldLabel: "审核人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reviewTimeString",
          display: true,
          fieldLabel: "审核时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 服务时间
    "searchForm.serverTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.serverTime = this.searchForm.serverTime.reverse();
        }
        this.searchForm.serverTimeBeginTimestamp = val[0] || "";
        this.searchForm.serverTimeEndTimestamp = val[1] || "";
      }
    },
    //质检时间
    "searchForm.qualityTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.qualityTime = this.searchForm.qualityTime.reverse();
        }
        this.searchForm.qualityTimeBeginTimestamp = val[0] || "";
        this.searchForm.qualityTimeEndTimestamp = val[1] || "";
      }
    },
    //审核时间
    "searchForm.reviewTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.reviewTime = this.searchForm.reviewTime.reverse();
        }
        this.searchForm.reviewTimeBeginTimestamp = val[0] || "";
        this.searchForm.reviewTimeEndTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["isQTStatus", "isCheckStatus"]),
          getFrameworkTree()
        ];
        let [statusRes, frameworkRes] = await Promise.all(promiseList);
        //质检状态下拉框
        this.qtStatusList = this.getFreezeResponse(statusRes, {
          path: "data.isQTStatus"
        });
        //审核状态下拉框
        this.auditStatusList = this.getFreezeResponse(statusRes, {
          path: "data.isCheckStatus"
        });
        //部门下拉框
        this.handleTreeData(frameworkRes.data.children);
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.selectData = data;
    },
    //查询服务单质检列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getCustomerServiceQTList({
          ...this.searchForm,
          frameworkId: this.searchForm.frameworkId.join("")
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      this.exportData(exportInspection);
    },
    async exportData(exportApi) {
      try {
        this.loading = true;
        let res = await exportApi({
          ...this.searchForm,
          frameworkId: this.searchForm.frameworkId.join("")
        });
        if (res.code == "OK") {
          let url = this.getFreezeResponse(res, {
            path: "data",
            defaultVal: ""
          });
          if (url) exportExcelByUrl(url);
          else {
            exportMessageHandle(this);
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "qualityInspectionCenter/customerServiceQualityDetail",
          params: {
            refresh: true,
            id: row.inspectionId,
            origin: this.$route.name
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
